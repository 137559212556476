<ng-container *ngIf="app.isDesktop">
    <div class="justify-content-between desktop">
        <form [formGroup]="form" class="d-flex flex-column gap-3">
            <div class="d-flex justify-content-between gap-3">
                <div class="form-group search-box-width  d-flex group__filter__custom">
                    <div *ngIf="isFiltered" class="searchBox" (click)="foucsOnSearch()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"/></svg>
                        <input #search type="text" formControlName="search">

                        <span
                            *ngIf="form.get('search')?.value?.length"
                            class="clear-wrapper"
                            (click)="clearSearch()">
                            <span class="clear">×</span>
                        </span>
                    </div>
                    <!-- just UI -->
                    <div *ngIf="!isFiltered" class="searchBox" (click)="foucsOnSearch()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"/></svg>
                        <input #search type="text" (click)="dummySearchLog()">

                        <span
                            *ngIf="form.get('search')?.value?.length"
                            class="clear-wrapper"
                            (click)="clearSearch()">
                            <span class="clear">×</span>
                        </span>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <app-add-button
                        [text]="addText"
                        [showIcon]="showIcon"
                        (click)="add.emit()"
                        *ngIf="isAdded && !isFiltered"
                        style="height: fit-content; width: max-content;"></app-add-button>
                    <app-add-button
                        [text]="addText"
                        [showIcon]="showIcon"
                        (click)="add.emit()"
                        *ngIf="isAdded && isFiltered"
                        style="height: fit-content; width: max-content;"></app-add-button>
                    <app-order-card-button
                        (onClick)="openOrderCardDialog.emit()"
                        *ngIf="orderCardBtn"
                        style="height: fit-content; width: max-content;"></app-order-card-button>
                    <app-bulk-upload-button
                        [text]="bulkText"
                        (click)="bulkUpload.emit()"
                        [showIcon]="showIcon"
                        *ngIf="isBulkUpload"
                        style="height: fit-content; width: max-content;"></app-bulk-upload-button>
                    <ng-content select="[tableHeaderActions]"></ng-content>

                    <ng-container *ngIf="isMarkAllOnline">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-danger border-radius btn-maskAs"
                            (click)="onMarkAllRejectedClick.emit()"
                            [disabled]="!enableToMarkAllOnlineOrdersRejected"
                            translate>
                            Mark All Today's Online Order Rejected
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-success border-radius btn-maskAs"
                            [disabled]="hasPaidOrder"
                            (click)="onMarkAllDeliveredClick.emit()"
                            [disabled]="!enableToMarkAllOnlineOrdersDelivered"
                            translate>
                            Mark All Today's Online Order Delivered
                        </button>
                    </ng-container>

                    <ng-container *ngTemplateOutlet="canteenOrderActions"></ng-container>

                    <app-export-button
                        [text]="reportText | translate"
                        [disabled]="disableReport"
                        (click)="!disableReport && report.emit()"
                        *ngIf="isReport"
                        style="height: fit-content; width: max-content;"></app-export-button>

                    <div class="dropdown-btn dropdown" *ngIf="isExport">
                        <button [disabled]="disableReport || !isCustomize" class="btn-sm btn-customize-columns dropdown-toggle" type="button" id="dropdownMenuButtonCustomize" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="17" viewBox="0 0 24 24"><g fill="none"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="currentColor" d="M8 3v18H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3Zm6 0v18h-4V3h4Zm5 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-3V3h3Z"/></g></svg>
                            <span class="columns-indicater {{this.totalNoOfColumns === defaultColumns.length ? 'hide-columns-indicater' : ''}}">
                                {{this.totalNoOfColumns}}/{{defaultColumns.length}}
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-customize" aria-labelledby="dropdownMenuButtonCustomize" (click)="$event.stopPropagation()">
                            <ng-container *ngFor="let column of defaultColumns; let i = index">
                                <button *ngIf="column.condition ?? true && column.dropdownVisible ?? true" class="dropdown-item d-flex gap-2 align-items-center" type="button" role="button" (click)="onColumnCheckBoxClick($event, i)">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [id]="i"
                                        [checked]="column.visible"
                                    />
                                    <label (click)="onColumnCheckBoxClick($event, i)" class="user-select-none" type="button" role="button" [for]="i" translate>{{ column.text }}</label>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="dropdown-btn dropdown" *ngIf="isExport">
                        <button [disabled]="disableReport" class="btn-sm btn-export-all dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="17" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button class="dropdown-item" type="button" role="button" (click)="export.emit()" translate>Only Current Page</button>
                            <button class="dropdown-item" type="button" role="button" (click)="isExportAll ? exportAll.emit() : export.emit()" translate>All Selected Records</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="desktop-filters-grid">
                <ng-container *ngIf="filteredByDate.length">
                    <div *ngIf="filteredByDate.includes('orderDate')" class="group__custom dateFilters">
                        <div class="d-flex flex-row justify-content-between gap-2 ">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text group__span__txt" translate>Order From</span>
                                <input
                                    type="date"
                                    class="form-control group__date__from text-primary"
                                    onkeydown="() => { return false }"
                                    formControlName="orderFromDate"
                                    (change)="filterRecord.emit()">

                                <span class="input-group-text" translate>To</span>
                                <input
                                    type="date"
                                    class="form-control group__date__to text-primary"
                                    onkeydown="() => { return false }"
                                    formControlName="orderToDate"
                                    (change)="filterRecord.emit()">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filteredByDate.includes('deliveryDate') || filteredByDate === 'date' || filteredByDate === 'date,actionDate'"
                        class="group__custom dateFilters">
                        <div class="d-flex flex-row justify-content-between gap-2 ">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text group__span__txt" translate>
                                    {{filteredByDate.includes('deliveryDate') ? 'Delivery ': ''}}From
                                </span>
                                <input
                                    type="date"
                                    class="form-control group__date__from text-primary"
                                    onkeydown="() => { return false }"
                                    formControlName="fromDate"
                                    (change)="filterRecord.emit()">

                                <span class="input-group-text" translate>To</span>
                                <input
                                    type="date"
                                    class="form-control group__date__to text-primary"
                                    onkeydown="() => { return false }"
                                    formControlName="toDate"
                                    (change)="filterRecord.emit()">
                            </div>
                        </div>
                    </div>

                    <div *ngIf="filteredByDate.includes('actionDate')" class="group__custom dateFilters">
                        <div class="d-flex flex-row justify-content-between gap-2 ">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text group__span__txt" translate>Approved From</span>
                                <input
                                    type="date"
                                    class="form-control group__date__from"
                                    onkeydown="() => { return false }"
                                    formControlName="actionDateFrom"
                                    (change)="filterRecord.emit()">

                                <span class="input-group-text" translate>To</span>
                                <input
                                    type="date"
                                    class="form-control group__date__to"
                                    onkeydown="() => { return false }"
                                    formControlName="actionDateTo"
                                    (change)="filterRecord.emit()">
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="canteenOptions && canteenOptions.length">
                    <div class=" {{multipleCanteen && 'col-sm-auto'}} ">
                        <ng-select
                            #canteenSelection
                            class="h-100 ng-select-sm w-100"
                            [items]="canteenOptions"
                            bindValue="value"
                            [formControlName]="formControlName"
                            [clearable]="true"
                            [placeholder]=" (isMerchantsPage ? 'Select Merchant': 'Select Canteen') | translate"
                            [multiple]="multipleCanteen"
                            [closeOnSelect]="!multipleCanteen"
                            (change)="multipleCanteen ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleCanteen" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="merchantEventOptions">
                    <div class="">
                        <ng-select
                            #merchantEventSelection
                            class="h-100 ng-select-sm"
                            formControlName="merchantEvent"
                            [clearable]="true"
                            [placeholder]="'merchant_events.SELECT'| translate"
                            [multiple]="multipleMerchantEvent"
                            [closeOnSelect]="!multipleMerchantEvent"
                            (change)="multipleMerchantEvent ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-option *ngFor="let item of merchantEventOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="merchantOptions?.length">
                    <div class="">
                        <ng-select
                            #merchantSelection
                            class="h-100 ng-select-sm"
                            formControlName="merchant"
                            [clearable]="true"
                            [placeholder]="'MERCHANT_INFO.SELECT'| translate"
                            [multiple]="multipleMerchant"
                            [closeOnSelect]="!multipleMerchant"
                            (change)="multipleMerchant ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-option *ngFor="let item of merchantOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="merchantBranchOptions?.length">
                    <div class="">
                        <ng-select
                            #merchantBranchSelection
                            class="h-100 ng-select-sm"
                            formControlName="merchantBranch"
                            [items]="merchantBranchOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Branches'| translate"
                            [multiple]="multipleMerchantBranch"
                            [closeOnSelect]="!multipleMerchantBranch"
                            (change)="multipleMerchantBranch ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleCanteen" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="merchantBranchInventoryOptions?.length">
                    <div class="">
                        <ng-select
                            #merchantBranchInventory
                            class="h-100 ng-select-sm"
                            formControlName="merchantBranchInventory"
                            [clearable]="true"
                            [placeholder]="'Select Inventories'| translate"
                            [multiple]="multipleMerchantBranchInventory"
                            [closeOnSelect]="!multipleMerchantBranchInventory"
                            (change)="multipleMerchantBranch ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-option *ngFor="let item of merchantBranchInventoryOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="schoolOptions && schoolOptions.length && showSchoolType">
                    <div class=" {{multipleSchool && 'col-sm-auto'}} ">
                        <ng-select
                            #schoolSelection
                            class="h-100 ng-select-sm"
                            formControlName="school"
                            [items]="schoolOptions"
                            bindValue="value"
                            [clearable]="schoolsFilterClearable"
                            [placeholder]="'Select School' | translate"
                            [multiple]="multipleSchool"
                            [closeOnSelect]="!multipleSchool"
                            (change)="multipleSchool ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleSchool" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="genderOptions && genderOptions.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Gender' | translate"
                            formControlName="gender"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of genderOptions" [value]="item.value" translate>
                                {{ item.label }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="studentOptions && studentOptions.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Student' | translate"
                            formControlName="student"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of studentOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="couponOptions && couponOptions.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Coupon' | translate"
                            formControlName="coupon"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of couponOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <!-- Typing filters Start -->
                <ng-container *ngIf="adultTypeaheadSelectConfig">
                    <div class="">
                        <app-typeahead-select
                            formControlName="adult"
                            [typingConfiguration]="adultTypeaheadSelectConfig"
                            (change)="filterRecord.emit()">
                        </app-typeahead-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="digitalCardStoreTypeaheadSelectConfig">
                    <div class="">
                        <app-typeahead-select
                            formControlName="digitalCardStore"
                            [typingConfiguration]="digitalCardStoreTypeaheadSelectConfig"
                            (change)="filterRecord.emit()">
                        </app-typeahead-select>
                    </div>
                </ng-container>
                <!-- Typing filters End -->

                <!-- Transactions type Start-->
                <ng-container *ngIf="transactionTypesOptions && transactionTypesOptions.length">
                    <div class=" col-sm-auto ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="trTypes"
                            [items]="transactionTypesOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Transaction Types' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <span class="text-{{item.value}}">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Transactions type End-->

                <!-- Requests Start-->
                <ng-container *ngIf="purposeOfRefundOptions && purposeOfRefundOptions.length">
                    <div class=" col-sm-auto ">
                        <ng-select
                            #purposeOfRefundSelection
                            class="h-100 ng-select-sm"
                            formControlName="purposeOfRefund"
                            [items]="purposeOfRefundOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Purpose' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="bankOptions && bankOptions.length">
                    <div class=" col-sm-auto ">
                        <ng-select
                            #bankSelection
                            class="h-100 ng-select-sm"
                            formControlName="bank"
                            [items]="bankOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Bank' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Requests End-->

                <!-- Money Requests Start -->
                <ng-container *ngIf="purposeOfTransferOptions && purposeOfTransferOptions.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="purposeOfTransfer"
                            [clearable]="true"
                            [placeholder]="'Select Purpose' | translate"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of purposeOfTransferOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="moneyRequestsStatusOptions && moneyRequestsStatusOptions.length">
                    <div class=" money-request-status-colors">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="status"
                            [clearable]="true"
                            [placeholder]="'Status' | translate"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of moneyRequestsStatusOptions" [value]="item.value">
                                <span [ngClass]="getMRColorByStatus(item.value)">{{ item.label | translate }}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="fromUserTypeaheadSelectConfig">
                    <div class="">
                        <app-typeahead-select
                            formControlName="fromUser"
                            [typingConfiguration]="fromUserTypeaheadSelectConfig"
                            (change)="filterRecord.emit()">
                        </app-typeahead-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="toUserTypeaheadSelectConfig">
                    <div class="">
                        <app-typeahead-select
                            formControlName="toUser"
                            [typingConfiguration]="toUserTypeaheadSelectConfig"
                            (change)="filterRecord.emit()">
                        </app-typeahead-select>
                    </div>
                </ng-container>
                <!-- Money Requests End -->

                <!-- Card Orders Page Start-->
                <ng-container *ngIf="cardOrderTypeOptions && cardOrderTypeOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Type' | translate"
                            formControlName="storeItemType"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of cardOrderTypeOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="themeOptions && themeOptions.length > 0">
                    <div class=" col-sm-auto ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="cardColor"
                            [items]="themeOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Theme' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="invoicesStatusOptions && invoicesStatusOptions.length > 0 && showInvoiceStatus">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Orders Invoices Status' | translate"
                            formControlName="hasInvoice"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of invoicesStatusOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Card Orders Page End-->

                <ng-container *ngIf="paymentMethodOptions && paymentMethodOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Method' | translate"
                            formControlName="paymentMethod"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of paymentMethodOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="paymentTokenOptions?.length && showPaymentToken">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Payment Token' | translate"
                            formControlName="paymentToken"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of paymentTokenOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="isAdmin && businessUserTypeOptions && businessUserTypeOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select User Type' | translate"
                            formControlName="businessUserType"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of businessUserTypeOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <!-- Dependents/Students Page Start -->
                <ng-container *ngIf="gradeOptions && gradeOptions.length > 0">
                    <div class=" {{multipleGrade && 'col-sm-auto'}} ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [items]="gradeOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Class' | translate"
                            [multiple]="multipleGrade"
                            [closeOnSelect]="!multipleGrade"
                            formControlName="grade"
                            (change)="multipleGrade ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleGrade" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="sectionOptions && sectionOptions.length > 0">
                    <div class=" {{multipleSection && 'col-sm-auto'}} ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [items]="sectionOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Section' | translate"
                            [multiple]="multipleSection"
                            [closeOnSelect]="!multipleSection"
                            formControlName="section"
                            (change)="multipleSection ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleSection" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="accessLevelOptions && accessLevelOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Access Level' | translate"
                            formControlName="accessLevel"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of accessLevelOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="availAmountOptions && availAmountOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Available Amount' | translate"
                            formControlName="availableAmount"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of availAmountOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="dailyLimitOptions && dailyLimitOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Daily Limit' | translate"
                            formControlName="dailyLimit"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of dailyLimitOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="cardNumberOptions && cardNumberOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Card Availability' | translate"
                            formControlName="cardSerialNoStatus"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of cardNumberOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="cardStatusOptions && cardStatusOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Card Status' | translate"
                            formControlName="cardStatus"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of cardStatusOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Dependents/Students Page End -->

                <!-- Adults Page Start -->
                <ng-container *ngIf="numberOfDependsOptions && numberOfDependsOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'# of Dependents' | translate"
                            formControlName="noOfDependents"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of numberOfDependsOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Adults Page End -->

                <!-- Coupon Start -->
                <ng-container *ngIf="redeemForOptions && redeemForOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Use' | translate"
                            formControlName="redeemFor"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of redeemForOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="publishStatusOptions && publishStatusOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Publish Status' | translate"
                            formControlName="isPublished"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of publishStatusOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Coupon End -->

                <!-- Food Item Start -->
                <ng-container *ngIf="foodCategoryOptions && foodCategoryOptions.length > 0">
                    <div class=" col-sm-auto ">
                        <ng-select
                            #foodCategorySelection
                            class="h-100 ng-select-sm"
                            formControlName="category"
                            [items]="foodCategoryOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Category' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>

                            <ng-template ng-footer-tmp *ngIf="enableShowMore">
                                <div>
                                    <button type="button" class="btn btn-sm btn-outline-primary mx-1 py-0" (click)="showMoreOptions.emit()" translate>Show more</button>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="sortByBannedListOptions && sortByBannedListOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Sort by the number of students' | translate"
                            formControlName="sortByBannedList"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of sortByBannedListOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="masterFoodItemOptions?.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="filterPlaceholder | translate"
                            formControlName="fromMasterFoodItem"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of masterFoodItemOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Food Item End -->

                <!-- Food Orders Start -->

                <ng-container *ngIf="orderTypeOptions && orderTypeOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'CANTEENS.FOOD_ORDERS.ORDER_TYPE' | translate"
                            (change)="filterOrderType.emit($event)">
                            <ng-option *ngFor="let item of orderTypeOptions" [value]="item">
                                {{ item | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Food Orders End -->

                <ng-container *ngIf="filterByDayOfWeek">
                    <div class=" col-sm-auto ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="day"
                            [items]="dayOfWeekOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select the day' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>

                <!-- Digital Cards Start -->
                <ng-container *ngIf="categoryOptions && categoryOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select category' | translate"
                            formControlName="category"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of categoryOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="digitalCardItemOptions && digitalCardItemOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Store Item' | translate"
                            formControlName="digitalCardItem"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of digitalCardItemOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="vendorOptions && vendorOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Vendor' | translate"
                            formControlName="vendor"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of vendorOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="vatMechanismOptions && vatMechanismOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'VAT Mechanism' | translate"
                            formControlName="vatMechanism"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of vatMechanismOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Digital Cards End -->

                <ng-container *ngIf="statusOptions && statusOptions.length > 0">
                    <div class=" {{multipleStatus && 'col-sm-auto'}} transfer-status-colors">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="status"
                            [items]="statusOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="statusPlaceHolder | translate"
                            [multiple]="multipleStatus"
                            [closeOnSelect]="!multipleStatus"
                            (change)="multipleStatus ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label" [ngClass]="getMRColorByStatus(item.value)">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes" [ngClass]="getMRColorByStatus(item.value)">
                                    <input *ngIf="multipleStatus" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="signUpStatusOptions && signUpStatusOptions.length > 0">
                    <div class=" {{multipleStatus && 'col-sm-auto'}} ">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="signUpStatus"
                            [items]="signUpStatusOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Status' | translate"
                            [multiple]="multipleStatus"
                            [closeOnSelect]="!multipleStatus"
                            (change)="multipleStatus ? filteringWithTimeOut() : filterRecord.emit()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="multipleStatus" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>

                <!-- Inventories and Devices Start -->
                <div class="" *ngIf="canteenInventoryOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Select Inventory' | translate"
                        formControlName="canteenInventory"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of canteenInventoryOptions" [value]="item.value">
                            <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="deviceOptions?.length && showDeviceType">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Select Device Type' | translate"
                        formControlName="deviceType"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of deviceOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="availQuantityOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Select Available Qty' | translate"
                        formControlName="availableQuantity"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of availQuantityOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <!-- Inventories and Devices End -->

                <!-- Attendances Start -->
                <div class="" *ngIf="checkInUsingOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Attendee Check In Token' | translate"
                        formControlName="checkInUsing"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of checkInUsingOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="attendeeCheckinStatusOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Check-in' | translate"
                        formControlName="checkIn"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of attendeeCheckinStatusOptions" [value]="item.value">
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="attendeeCheckoutStatusOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Check-out' | translate"
                        formControlName="checkOut"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of attendeeCheckoutStatusOptions" [value]="item.value">
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="checkOutUsingOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Attendee Check Out Token' | translate"
                        formControlName="checkOutUsing"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of checkOutUsingOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="earlyStatusOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Is Early?' | translate"
                        formControlName="isEarly"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of earlyStatusOptions" [value]="item.value">
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="lateStatusOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Is Late?' | translate"
                        formControlName="isLate"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of lateStatusOptions" [value]="item.value">
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="absentStatusOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'Is Absent?' | translate"
                        formControlName="isAbsent"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of absentStatusOptions" [value]="item.value">
                            <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                        </ng-option>
                    </ng-select>
                </div>
                <!-- Attendances End -->

                <!-- Call logs start -->
                <div class="" *ngIf="calledByOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'grid.call_log.CALLED_BY' | translate"
                        formControlName="calledBy"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of calledByOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="" *ngIf="pickedUpByOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'grid.call_log.PICKUP_BY' | translate"
                        formControlName="pickedUpBy"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of calledByOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <div *ngIf="dismissedByOptions?.length">
                    <ng-select
                        class="h-100 ng-select-sm"
                        [clearable]="true"
                        [placeholder]="'grid.call_log.DISMISSED_BY' | translate"
                        formControlName="dismissedBy"
                        (change)="filterRecord.emit()">
                        <ng-option *ngFor="let item of dismissedByOptions" [value]="item.value">
                            {{ item.label | translate }}
                        </ng-option>
                    </ng-select>
                </div>
                <!-- Call logs End -->

                <!-- Schools Start -->
                <ng-container *ngIf="cityOptions && cityOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select City' | translate"
                            formControlName="city"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of cityOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Schools End -->

                <!-- Fees Start -->
                <ng-container *ngIf="feeTypeOptions && feeTypeOptions.length > 0 && showFeeType">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Fee Type' | translate"
                            formControlName="trType"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of feeTypeOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Fees End -->

                <!-- Offers Start -->
                <ng-container *ngIf="couponForOptions && couponForOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Coupon For' | translate"
                            formControlName="couponFor"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of couponForOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="discountTypeOptions && discountTypeOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Discount Type' | translate"
                            formControlName="discountType"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of discountTypeOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Offers End -->

                <!-- Notification Start -->
                <ng-container *ngIf="titleOptions && titleOptions.length > 0">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Title' | translate"
                            formControlName="title"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of titleOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Notification End -->

                <!-- User Start -->
                <ng-container *ngIf="payByFaceOptions?.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Face ID Status' | translate"
                            formControlName="payByFaceEnabled"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of payByFaceOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="hasFacePicOptions?.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Image Uploaded' | translate"
                            formControlName="hasFacePic"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of hasFacePicOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="accountTypeOptions?.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Account Type' | translate"
                            formControlName="accountType"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of accountTypeOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- User End -->
            </div>
        </form>
    </div>

    <ng-template #canteenOrderActions>
        <ng-container *ngIf="isFoodOrdersPage">
            <div *ngIf="isAdmin || isSuperCanteenUser" class="btn-today">
                <button
                    type="button"
                    class="btn btn-sm btn-customize"
                    [disabled]="disableReport"
                    (click)="!disableReport && printOrders.emit()"
                    translate>
                    {{ 'canteen.canteen_orders.actions.PRINT_ORDERS' }}
                </button>
            </div>
            <div *ngIf="isGetTodayOrders" class="btn-today">
                <button
                    type="button"
                    class="btn btn-sm btn-customize"
                    [ngClass]="toggleTodayOrderFilter ? 'btn-primary text-white': 'btn-outline-primary'"
                    (click)="getTodayOrders.emit()"
                    translate>
                    {{ 'canteen.canteen_orders.actions.TODAY_APP_ORDER' }}
                </button>
            </div>
            <app-export-button
                [text]="'canteen.canteen_orders.actions.EXPORT_PENDING_APP_ORDERS' | translate"
                [disabled]="disableReport"
                (click)="!disableReport && pendingOrdersReport.emit()"
                style="height: fit-content; width: max-content;"></app-export-button>
        </ng-container>
    </ng-template>
</ng-container>

<ng-container *ngIf="!app.isDesktop">
    <div class="justify-content-between filter__actions mobile">
        <div [formGroup]="form" class="d-flex justify-content-between gap-3 flex-wrap-reverse mb-3">
            <ng-container *ngIf="isFiltered">
                <div class="searchBox search-box-max-width-mobile" (click)="foucsOnSearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"/></svg>
                    <input #search type="text" formControlName="search">

                    <span
                        *ngIf="form.get('search')?.value?.length"
                        class="clear-wrapper"
                        (click)="clearSearch()">
                        <span class="clear">×</span>
                    </span>
                </div>
            </ng-container>
            <!-- just UI -->
            <ng-container *ngIf="!isFiltered">
                <div class="searchBox search-box-max-width-mobile" (click)="foucsOnSearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"/></svg>
                    <input #search type="text" (click)="dummySearchLog()">

                    <span
                        *ngIf="form.get('search')?.value?.length"
                        class="clear-wrapper"
                        (click)="clearSearch()">
                        <span class="clear">×</span>
                    </span>
                </div>
            </ng-container>
            <div class="d-flex flex-wrap gap-3 {{app.isRTL ? 'mr-auto' : 'ml-auto'}}">
                <ng-container *ngIf="isBulkUpload || orderCardBtn">
                    <app-bulk-upload-button *ngIf="isBulkUpload" [text]="bulkText" [size]="'btn-sm'" (click)="bulkUpload.emit()"></app-bulk-upload-button>
                    <app-order-card-button (onClick)="openOrderCardDialog.emit()" *ngIf="orderCardBtn"></app-order-card-button>
                </ng-container>
                <div class="col" *ngIf="isAdded">
                    <app-add-button
                        [text]="addText"
                        [size]="'btn-sm'"
                        [showIcon]="showIcon"
                        (click)="add.emit()"></app-add-button>
                </div>
                <div class="col-auto dropdown-btn dropdown" *ngIf="isExport">
                    <button class="btn-sm btn-export-all dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="17" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
                    </button>
                    <div class="dropdown-menu dropdown-menu-mobile" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" role="button" (click)="export.emit()" translate>Only Current Page</button>
                        <button class="dropdown-item" role="button" (click)="isExportAll ? exportAll.emit() : export.emit()" translate>All Selected Records</button>
                    </div>
                </div>
                <!-- <div class="col-auto text-right" *ngIf="isExport">
                    <app-export-button [size]="'btn-sm'" [disabled]="disableReport" (click)="!disableReport && export.emit()"></app-export-button>
                </div> -->

                <div *ngIf="isAdmin || isSuperCanteenUser" class="col-auto text-right btn-today">
                    <button
                        type="button"
                        class="btn btn-sm btn-customize"
                        [disabled]="disableReport"
                        (click)="!disableReport && printOrders.emit()"
                        translate>
                        {{ 'canteen.canteen_orders.actions.PRINT_ORDERS' }}
                    </button>
                </div>
                <div *ngIf="isGetTodayOrders" class="col-auto text-right btn-today">
                    <button
                        type="button"
                        class="btn btn-sm btn-customize"
                        [ngClass]="toggleTodayOrderFilter ? 'btn-primary text-white': 'btn-outline-primary'"
                        (click)="getTodayOrders.emit()"
                        translate>
                        {{ 'canteen.canteen_orders.actions.TODAY_APP_ORDER' }}
                    </button>
                </div>
                <div class="col-auto text-right" *ngIf="isFoodOrdersPage">
                    <app-export-button
                        [size]="'btn-sm'"
                        [text]="'canteen.canteen_orders.actions.EXPORT_PENDING_APP_ORDERS' | translate"
                        [disabled]="disableReport"
                        (click)="!disableReport && pendingOrdersReport.emit()"
                        style="height: fit-content; width: max-content;"></app-export-button>
                </div>
                <div class="col-auto text-right" *ngIf="isReport">
                    <app-export-button
                        [size]="'btn-sm'"
                        [text]="reportText | translate"
                        [disabled]="disableReport"
                        (click)="!disableReport && report.emit()"
                        style="height: fit-content; width: max-content;"></app-export-button>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <form [formGroup]="form" class="w-100">
                <div class="mobile-filters">
                    <ng-container *ngIf="filteredByDate.length">
                        <div
                            *ngIf="filteredByDate.includes('orderDate')"
                            class="group__custom dateFilters ">
                            <div class="date-filters-mobile-width d-flex flex-row justify-content-between">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text group__span__txt" translate>Order From</span>
                                    <input
                                        type="date"
                                        class="form-control group__date__from"
                                        onkeydown="() => { return false }"
                                        formControlName="orderFromDate"
                                        (change)="filterRecord.emit()">

                                    <span class="input-group-text" translate>To</span>
                                    <input
                                        type="date"
                                        class="form-control group__date__to"
                                        onkeydown="() => { return false }"
                                        formControlName="orderToDate"
                                        (change)="filterRecord.emit()">
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="filteredByDate.includes('deliveryDate') || filteredByDate === 'date' || filteredByDate === 'date,actionDate'"
                            class="group__custom dateFilters ">
                            <div class="date-filters-mobile-width d-flex flex-row justify-content-between">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text group__span__txt" translate>
                                        {{filteredByDate.includes('deliveryDate') ? 'Delivery ' : ''}}From
                                    </span>
                                    <input
                                        type="date"
                                        class="form-control group__date__from"
                                        onkeydown="() => { return false }"
                                        formControlName="fromDate"
                                        (change)="filterRecord.emit()">

                                    <span class="input-group-text" translate>To</span>
                                    <input
                                        type="date"
                                        class="form-control group__date__to"
                                        onkeydown="() => { return false }"
                                        formControlName="toDate"
                                        (change)="filterRecord.emit()">
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="filteredByDate.includes('actionDate')"
                            class="group__custom dateFilters ">
                            <div class="date-filters-mobile-width d-flex flex-row justify-content-between">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text group__span__txt" translate>Approved From</span>
                                    <input
                                        type="date"
                                        class="form-control group__date__from"
                                        onkeydown="() => { return false }"
                                        formControlName="actionDateFrom"
                                        (change)="filterRecord.emit()">

                                    <span class="input-group-text" translate>To</span>
                                    <input
                                        type="date"
                                        class="form-control group__date__to"
                                        onkeydown="() => { return false }"
                                        formControlName="actionDateTo"
                                        (change)="filterRecord.emit()">
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="canteenOptions && canteenOptions.length">
                        <div class="">
                            <ng-select
                                #canteenSelection
                                class="h-100 ng-select-sm"
                                [items]="canteenOptions"
                                bindValue="value"
                                [formControlName]="formControlName"
                                [clearable]="true"
                                [placeholder]=" (isMerchantsPage ? 'Select Merchant': 'Select Canteen') | translate"
                                [multiple]="multipleCanteen"
                                [closeOnSelect]="!multipleCanteen"
                                (change)="multipleCanteen ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleCanteen" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="merchantEventOptions">
                        <div class="">
                            <ng-select
                                #merchantEventSelection
                                class="h-100 ng-select-sm"
                                formControlName="merchantEvent"
                                [clearable]="true"
                                [placeholder]="'merchant_events.SELECT'| translate"
                                [multiple]="multipleMerchantEvent"
                                [closeOnSelect]="!multipleMerchantEvent"
                                (change)="multipleMerchantEvent ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-option *ngFor="let item of merchantEventOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="merchantOptions && merchantOptions.length">
                        <div class="">
                            <ng-select
                                #merchantSelection
                                class="h-100 ng-select-sm"
                                formControlName="merchant"
                                [clearable]="true"
                                [placeholder]="'MERCHANT_INFO.SELECT' | translate"
                                [multiple]="multipleMerchant"
                                [closeOnSelect]="!multipleMerchant"
                                (change)="multipleMerchant ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-option *ngFor="let item of merchantOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="merchantBranchOptions?.length">
                        <div class="">
                            <ng-select
                                #merchantBranchSelection
                                class="h-100 ng-select-sm"
                                formControlName="merchantBranch"
                                [items]="merchantBranchOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Branches'| translate"
                                [multiple]="multipleMerchantBranch"
                                [closeOnSelect]="!multipleMerchantBranch"
                                (change)="multipleMerchantBranch ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleCanteen" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="merchantBranchInventoryOptions?.length">
                        <div class="">
                            <ng-select
                                #merchantBranchInventory
                                class="h-100 ng-select-sm"
                                formControlName="merchantBranchInventory"
                                [clearable]="true"
                                [placeholder]="'Select Inventories'| translate"
                                [multiple]="multipleMerchantBranchInventory"
                                [closeOnSelect]="!multipleMerchantBranchInventory"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of merchantBranchInventoryOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="schoolOptions && schoolOptions.length  && showSchoolType">
                        <div class="">
                            <ng-select
                                #schoolSelection
                                class="h-100 ng-select-sm"
                                formControlName="school"
                                [items]="schoolOptions"
                                bindValue="value"
                                [clearable]="schoolsFilterClearable"
                                [placeholder]="'Select School' | translate"
                                [multiple]="multipleSchool"
                                [closeOnSelect]="!multipleSchool"
                                (change)="multipleSchool ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleSchool" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="genderOptions && genderOptions.length">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Gender' | translate"
                                [searchable]="false"
                                formControlName="gender"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of genderOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="studentOptions && studentOptions.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Student' | translate"
                                formControlName="student"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of studentOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="couponOptions && couponOptions.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Coupon' | translate"
                                formControlName="coupon"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of couponOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="adultTypeaheadSelectConfig">
                        <div class="form-group">
                            <app-typeahead-select
                                formControlName="adult"
                                [typingConfiguration]="adultTypeaheadSelectConfig"
                                (change)="filterRecord.emit()">
                            </app-typeahead-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="digitalCardStoreTypeaheadSelectConfig">
                        <div class="form-group">
                            <app-typeahead-select
                                formControlName="digitalCardStore"
                                [typingConfiguration]="digitalCardStoreTypeaheadSelectConfig"
                                (change)="filterRecord.emit()">
                            </app-typeahead-select>
                        </div>
                    </ng-container>
                <!-- Transactions type Start-->
                <ng-container *ngIf="transactionTypesOptions && transactionTypesOptions.length">
                    <div class="">
                        <ng-select
                            class="h-100 ng-select-sm"
                            formControlName="trTypes"
                            [items]="transactionTypesOptions"
                            bindValue="value"
                            [clearable]="true"
                            [placeholder]="'Select Transaction Types' | translate"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            [searchable]="false"
                            (change)="filteringWithTimeOut()">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="ng-value-label">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                <div class="multiselect-dropdown-checkboxes">
                                    <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                    <span class="text-{{item.value}}">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </ng-container>
                <!-- Transactions type End-->

                    <!-- Requests Start-->
                    <ng-container *ngIf="purposeOfRefundOptions && purposeOfRefundOptions.length">
                        <div class="">
                            <ng-select
                                #purposeOfRefundSelection
                                class="h-100 ng-select-sm"
                                formControlName="purposeOfRefund"
                                [items]="purposeOfRefundOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Purpose' | translate"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [searchable]="false"
                                (change)="filteringWithTimeOut()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="bankOptions && bankOptions.length">
                        <div class="">
                            <ng-select
                                #bankSelection
                                class="h-100 ng-select-sm"
                                formControlName="bank"
                                [items]="bankOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Bank' | translate"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                (change)="filteringWithTimeOut()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Requests End-->

                    <!-- Money Requests Start -->
                    <ng-container *ngIf="purposeOfTransferOptions && purposeOfTransferOptions.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="purposeOfTransfer"
                                [clearable]="true"
                                [placeholder]="'Select Purpose' | translate"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of purposeOfTransferOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="moneyRequestsStatusOptions && moneyRequestsStatusOptions.length">
                        <div class="form-group  money-request-status-colors">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="status"
                                [clearable]="true"
                                [placeholder]="'Status' | translate"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of moneyRequestsStatusOptions" [value]="item.value">
                                    <span [ngClass]="getMRColorByStatus(item.value)">{{ item.label | translate }}</span>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="fromUserTypeaheadSelectConfig">
                        <div class="form-group ">
                            <app-typeahead-select
                                formControlName="fromUser"
                                [typingConfiguration]="fromUserTypeaheadSelectConfig"
                                (change)="filterRecord.emit()">
                            </app-typeahead-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="toUserTypeaheadSelectConfig">
                        <div class="form-group ">
                            <app-typeahead-select
                                formControlName="toUser"
                                [typingConfiguration]="toUserTypeaheadSelectConfig"
                                (change)="filterRecord.emit()">
                            </app-typeahead-select>
                        </div>
                    </ng-container>
                    <!-- Money Requests End -->

                    <!-- Card Orders Page Start-->
                    <ng-container *ngIf="cardOrderTypeOptions && cardOrderTypeOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Type' | translate"
                                [searchable]="false"
                                formControlName="storeItemType"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of cardOrderTypeOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="themeOptions && themeOptions.length > 0">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="cardColor"
                                [items]="themeOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Theme' | translate"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [searchable]="false"
                                (change)="filteringWithTimeOut()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="invoicesStatusOptions && invoicesStatusOptions.length > 0 && showInvoiceStatus">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Orders Invoices Status' | translate"
                                formControlName="hasInvoice"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of invoicesStatusOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Card Orders Page End-->

                    <ng-container *ngIf="paymentMethodOptions && paymentMethodOptions.length > 0">
                        <div     class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Method' | translate"
                                formControlName="paymentMethod"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of paymentMethodOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="paymentTokenOptions?.length && showPaymentToken">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Payment Token' | translate"
                                formControlName="paymentToken"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of paymentTokenOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isAdmin && businessUserTypeOptions && businessUserTypeOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select User Type' | translate"
                                formControlName="businessUserType"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of businessUserTypeOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <!-- Dependents/Students Page Start -->
                    <ng-container *ngIf="gradeOptions && gradeOptions.length > 0">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [items]="gradeOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Grade' | translate"
                                [multiple]="multipleGrade"
                                [closeOnSelect]="!multipleGrade"
                                formControlName="grade"
                                (change)="multipleGrade ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleGrade" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="sectionOptions && sectionOptions.length > 0">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [items]="sectionOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Section' | translate"
                                [multiple]="multipleSection"
                                [closeOnSelect]="!multipleSection"
                                formControlName="section"
                                (change)="multipleSection ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleSection" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="accessLevelOptions && accessLevelOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Access Level' | translate"
                                formControlName="accessLevel"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of accessLevelOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="availAmountOptions && availAmountOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Available Amount' | translate"
                                formControlName="availableAmount"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of availAmountOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="dailyLimitOptions && dailyLimitOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Daily Limit' | translate"
                                formControlName="dailyLimit"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of dailyLimitOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="cardNumberOptions && cardNumberOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Card Availability' | translate"
                                formControlName="cardSerialNoStatus"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of cardNumberOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="cardStatusOptions && cardStatusOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Card Status' | translate"
                                formControlName="cardStatus"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of cardStatusOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Dependents/Students Page End -->

                    <!-- Adults Page Start -->
                    <ng-container *ngIf="numberOfDependsOptions && numberOfDependsOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'# of Dependents' | translate"
                                formControlName="noOfDependents"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of numberOfDependsOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Adults Page End -->

                    <!-- Coupon Start -->
                    <ng-container *ngIf="redeemForOptions && redeemForOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Use' | translate"
                                formControlName="redeemFor"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of redeemForOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="publishStatusOptions && publishStatusOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Publish Status' | translate"
                                formControlName="isPublished"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of publishStatusOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Coupon End -->

                    <!-- Food Item Start -->
                    <ng-container *ngIf="foodCategoryOptions && foodCategoryOptions.length > 0">
                        <div class="">
                            <ng-select
                                #foodCategorySelection
                                class="h-100 ng-select-sm"
                                formControlName="category"
                                [items]="foodCategoryOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Category' | translate"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                (change)="filteringWithTimeOut()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                                <ng-template ng-footer-tmp *ngIf="enableShowMore">
                                    <div>
                                        <button type="button" class="btn btn-sm btn-outline-primary mx-1 py-0" (click)="showMoreOptions.emit()" translate>Show more</button>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="sortByBannedListOptions && sortByBannedListOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Sort by the number of students' | translate"
                                formControlName="sortByBannedList"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of sortByBannedListOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="masterFoodItemOptions?.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="filterPlaceholder | translate"
                                formControlName="fromMasterFoodItem"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of masterFoodItemOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Food Item End -->

                    <ng-container *ngIf="filterByDayOfWeek">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="day"
                                [items]="dayOfWeekOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select the day' | translate"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [searchable]="false"
                                (close)="filterRecord.emit()"
                                (clear)="filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="true" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <!-- Digital Cards Start -->
                    <ng-container *ngIf="categoryOptions && categoryOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select category' | translate"
                                formControlName="category"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of categoryOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="digitalCardItemOptions && digitalCardItemOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Store Item' | translate"
                                formControlName="digitalCardItem"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of digitalCardItemOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vendorOptions && vendorOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Vendor' | translate"
                                formControlName="vendor"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of vendorOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vatMechanismOptions && vatMechanismOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'VAT Mechanism' | translate"
                                formControlName="vatMechanism"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of vatMechanismOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Digital Cards End -->

                    <ng-container *ngIf="statusOptions && statusOptions.length > 0">
                        <div class="transfer-status-colors">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="status"
                                [items]="statusOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="statusPlaceHolder | translate"
                                [multiple]="multipleStatus"
                                [closeOnSelect]="!multipleStatus"
                                [searchable]="false"
                                (change)="multipleStatus ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label" [ngClass]="getMRColorByStatus(item.value)">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes" [ngClass]="getMRColorByStatus(item.value)">
                                        <input *ngIf="multipleStatus" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="signUpStatusOptions && signUpStatusOptions.length > 0">
                        <div class="">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="signUpStatus"
                                [items]="signUpStatusOptions"
                                bindValue="value"
                                [clearable]="true"
                                [placeholder]="'Select Status' | translate"
                                [multiple]="multipleStatus"
                                [closeOnSelect]="!multipleStatus"
                                [searchable]="false"
                                (change)="multipleStatus ? filteringWithTimeOut() : filterRecord.emit()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span class="ng-value-label">
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item" let let-item$="item$" let-index="index">
                                    <div class="multiselect-dropdown-checkboxes">
                                        <input *ngIf="multipleStatus" class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"  [ngModelOptions]="{standalone: true}"/>
                                        <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                        <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </ng-container>


                    <!-- Inventories and Devices Start -->
                    <div class="form-group " *ngIf="canteenInventoryOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Inventory' | translate"
                            formControlName="canteenInventory"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of canteenInventoryOptions" [value]="item.value">
                                <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="deviceOptions?.length && showDeviceType">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Device Type' | translate"
                            formControlName="deviceType"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of deviceOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="availQuantityOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Select Available Qty' | translate"
                            formControlName="availableQuantity"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of availQuantityOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <!-- Inventories and Devices End -->

                    <!-- Attendances Start -->
                    <div class="form-group " *ngIf="checkInUsingOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Attendee Check In Token' | translate"
                            formControlName="checkInUsing"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of checkInUsingOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="attendeeCheckinStatusOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Check-in' | translate"
                            formControlName="checkIn"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of attendeeCheckinStatusOptions" [value]="item.value">
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="attendeeCheckoutStatusOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Check-out' | translate"
                            formControlName="checkOut"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of attendeeCheckoutStatusOptions" [value]="item.value">
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="checkOutUsingOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Attendee Check Out Token' | translate"
                            formControlName="checkOutUsing"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of checkOutUsingOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="earlyStatusOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Is Early?' | translate"
                            formControlName="isEarly"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of earlyStatusOptions" [value]="item.value">
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="lateStatusOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Is Late?' | translate"
                            formControlName="isLate"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of lateStatusOptions" [value]="item.value">
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group " *ngIf="absentStatusOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [placeholder]="'Is Absent?' | translate"
                            formControlName="isAbsent"
                            [searchable]="false"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of absentStatusOptions" [value]="item.value">
                                <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                            </ng-option>
                        </ng-select>
                    </div>
                    <!-- Attendances End -->

                    <!-- Call logs start -->
                    <div class="form-group" *ngIf="calledByOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [searchable]="false"
                            [placeholder]="'grid.call_log.CALLED_BY' | translate"
                            formControlName="calledBy"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of calledByOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group" *ngIf="pickedUpByOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [searchable]="false"
                            [placeholder]="'grid.call_log.PICKUP_BY' | translate"
                            formControlName="pickedUpBy"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of calledByOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group" *ngIf="dismissedByOptions?.length">
                        <ng-select
                            class="h-100 ng-select-sm"
                            [clearable]="true"
                            [searchable]="false"
                            [placeholder]="'grid.call_log.DISMISSED_BY' | translate"
                            formControlName="dismissedBy"
                            (change)="filterRecord.emit()">
                            <ng-option *ngFor="let item of dismissedByOptions" [value]="item.value">
                                {{ item.label | translate }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <!-- Call logs End -->

                    <!-- Schools Start -->
                    <ng-container *ngIf="cityOptions && cityOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select City' | translate"
                                formControlName="city"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of cityOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Schools End -->

                    <!-- Fees Start -->
                    <ng-container *ngIf="feeTypeOptions && feeTypeOptions.length > 0 && showFeeType">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Fee Type' | translate"
                                formControlName="trType"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of feeTypeOptions" [value]="item.value">
                                    <ng-container *ngIf="item.translations">{{ item.translations | translation }}</ng-container>
                                    <ng-container *ngIf="!item.translations">{{ item.label | translate }}</ng-container>
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Fees End -->

                    <!-- Offers Start -->
                    <ng-container *ngIf="couponForOptions && couponForOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Coupon For' | translate"
                                formControlName="couponFor"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of couponForOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="discountTypeOptions && discountTypeOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Discount Type' | translate"
                                formControlName="discountType"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of discountTypeOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Offers End -->

                    <!-- Notification Start -->
                    <ng-container *ngIf="titleOptions && titleOptions.length > 0">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Select Title' | translate"
                                formControlName="title"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of titleOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- Notification End -->

                    <!-- User Start -->
                    <ng-container *ngIf="payByFaceOptions?.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="payByFaceEnabled"
                                [clearable]="true"
                                [placeholder]="'Face ID Status' | translate"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of payByFaceOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="hasFacePicOptions?.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                [clearable]="true"
                                [placeholder]="'Image Uploaded' | translate"
                                formControlName="hasFacePic"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of hasFacePicOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="accountTypeOptions?.length">
                        <div class="form-group ">
                            <ng-select
                                class="h-100 ng-select-sm"
                                formControlName="accountType"
                                [clearable]="true"
                                [placeholder]="'Account Type' | translate"
                                [searchable]="false"
                                (change)="filterRecord.emit()">
                                <ng-option *ngFor="let item of accountTypeOptions" [value]="item.value">
                                    {{ item.label | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                    <!-- User End -->
                </div>
            </form>
        </div>
    </div>
</ng-container>

