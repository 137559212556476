import { IBaseConfig } from "@portal/common/config/config.model";
import { commonEnvironment } from "@portal/common/environment/environment";
import { NgxLoggerLevel } from "ngx-logger";

export const environment: IBaseConfig = {
    ...commonEnvironment,
    production: true,
    APP_BASE_PATH: 'https://portal.waki.sa',
    API_BASE_PATH: 'https://api.waki.sa',
    IMAGE_BASE_PATH: 'https://resources.waki.sa',
    SOCKET_URL: 'wss://api.waki.sa',
    featureFlags: {
        ...commonEnvironment.featureFlags,
    },
    logging: {
        disableConsoleLogging: false,
        logLevel: NgxLoggerLevel.DEBUG,
        enableSourceMaps: true,
        serverLoggingUrl: null,
    },
    recaptcha: {
        siteKey: '6Lfg2ykiAAAAAILwM8Ui0IfSpHfId2AfrSGEjqio',
    },
};
